import { combinedSagas as combinedPersistence } from './modules/Persistence/saga';
import { combinedSagas as combinedApp } from './modules/App/saga';
import { combinedSagas as combinedRouteTransitions } from './modules/App/transitions';
import { combinedSagas as combinedAddressPicker } from './modules/AddressPicker/saga';
import { namesArray as addressPickerActionNames } from 'redux/modules/AddressPicker/constants';
import { combinedSagas as combinedEnergySelection } from './modules/Energy/ProductSelectionForm/saga';
import { combinedSagas as combinedEntryOrganic } from './modules/EntryOrganic/saga';
import { combinedSagas as combinedEntryForm } from './modules/EntryForm/saga';
import { combinedSagas as combinedYourDetails } from './modules/YourDetails/saga';
import { combinedSagas as combinedMobile } from './modules/Mobile/saga';
import { combinedSagas as combinedPaymentForm } from './modules/PaymentForm/saga';
import { combinedSagas as combinedFingerprintsWithChallenge } from './modules/FingerprintsWithChallenge/saga';
import { combinedSagas as combinedEnergyQuote } from './modules/Energy/Quote/saga';
import { combinedSagas as combinedServiceSelection } from './modules/ServiceSelection/saga';
import { combinedSagas as combinedBroadband } from './modules/Broadband/saga';
import { combinedSagas as combinedGoldBenefit } from './modules/GoldBenefit/saga';
import { combinedSagas as combinedOrderConfirmation } from './modules/OrderSummary/saga';
import { combinedSagas as combinedContent } from './modules/Content/saga';
import { combinedSagas as combinedCBCChallenge } from './modules/CBCChallenge/saga';
import { combinedSagas as combinedEligibility } from './modules/Eligibility/saga';
import { combinedSagas as combinedCashback } from './modules/Cashback/saga';
import { combinedSagas as combinedPartnerLogin } from './modules/PartnerLogin/saga';
import { combinedSagas as combinedBundles } from './modules/Bundles/saga';
import { combinedSagas as combinedSession } from './modules/Session/saga';
import { combinedSagas as mobileContractRenewal } from './modules/MobileContractRenewal/saga';
import { combinedSagas as homeInsuranceContractRenewal } from './modules/HomeInsuranceRenewal/saga';
import { combinedSagas as MobileRenewal } from './modules/MobileRenewal/saga';
import { combinedSagas as BroadbandRenewal } from './modules/BroadbandRenewal/saga';
import { combinedSagas as smartMeterInterestRegistration } from './modules/SmartMeterInterest/saga';
import { combinedSagas as combinedBoilerCover } from './modules/BoilerCover/saga';
import { combinedSagas as combinedHomeInsurance } from './modules/HomeInsurance/saga';
import { combinedSagas as combinedRemoteSession } from './modules/RemoteSession/v2/saga';
import { combinedSagas as combinedSalesLogin } from './modules/SalesLogin/saga';
import { combinedSagas as combinedRestore } from './modules/Restore/saga';
import { combinedSagas as combinedHomeAudit } from './modules/HomeAudit/saga';
import { combinedSagas as combinedBillIncomeProtector } from './modules/BillIncomeProtector/saga';
import {
  combinedSagas as combinedFeatureFlags,
  featureFlagsInit,
} from './modules/FeatureFlags/saga';
import analyticsSaga from 'redux/modules/Analytics/saga';
import { combinedSagas as combinedPreselect } from './modules/Preselect/saga';
import { combinedSagas as combinedPreselectParameters } from './modules/Preselect/Parameters/saga';
import { combinedSagas as combinedLeadCapture } from './modules/LeadCapture/saga';

import { all, call, put } from 'redux-saga/effects';
import * as actions from 'app/redux/modules/Persistence/actions';
import * as PersistenceEngine from 'app/redux/modules/Persistence/engine.local';
import { renderApp } from './modules/App/actions';

function* sagasLoaded() {
  yield featureFlagsInit();
  yield put(renderApp());
}

export default function* rootSaga() {
  yield loadState();
  yield all([
    combinedPersistence(),
    combinedApp(),
    combinedSession(),
    combinedRouteTransitions(),
    combinedAddressPicker(),
    ...addressPickerActionNames.map((name) => combinedAddressPicker(name)),
    combinedEnergySelection(),
    combinedEntryForm(),
    combinedEntryOrganic(),
    combinedYourDetails(),
    combinedMobile(),
    combinedPaymentForm(),
    combinedFingerprintsWithChallenge(),
    combinedEnergyQuote(),
    combinedServiceSelection(),
    combinedBroadband(),
    combinedGoldBenefit(),
    combinedOrderConfirmation(),
    combinedContent(),
    combinedCBCChallenge(),
    combinedEligibility(),
    combinedCashback(),
    combinedPartnerLogin(),
    combinedBundles(),
    mobileContractRenewal(),
    homeInsuranceContractRenewal(),
    MobileRenewal(),
    BroadbandRenewal(),
    smartMeterInterestRegistration(),
    combinedBoilerCover(),
    combinedHomeInsurance(),
    combinedRemoteSession(),
    combinedFeatureFlags(),
    combinedSalesLogin(),
    analyticsSaga(),
    combinedRestore(),
    combinedHomeAudit(),
    combinedBillIncomeProtector(),
    combinedPreselect(),
    combinedPreselectParameters(),
    combinedLeadCapture(),
    sagasLoaded(),
  ]);
}

export function* loadState() {
  yield put(actions.serverLoad.request());
  const storedState = yield call(PersistenceEngine.load);
  if (storedState) {
    yield put(actions.serverLoad.success(storedState));
  }
}
