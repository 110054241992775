export default (address, join = ', ') => {
  const {
    postcode,
    town,
    street,
    subStreet,
    buildingName,
    buildingNumber,
    flatNumber,
    organisation,
    county,
  } = address;

  const addressLines = [
    organisation,
    flatNumber,
    buildingName,
    buildingNumber,
    subStreet,
    street,
    town,
    county,
    postcode,
  ];

  const mapped = addressLines.filter((l) => !!l).map((l) => l.trim());

  return join ? mapped.join(join) : mapped;
};
