import { PartnerLoginApi } from '../modules/PartnerLogin/api';

// TODO: All of these need a bit of tinkering - shouldn't really be accessing the store like this
// TODO: PLEASE DO NOT USE FUNCTIONS FROM THIS FILE - to be removed
// TODO: keep in mind that using these function may introduce very hard to debug race condition issues

export const dispatchAction = (action) => {
  window.store.dispatch(action);
};

export const getCurrentApplication = () => {
  return window.store.getState().toJS().App.application.id;
};

export const getCurrentToken = () => window.store.getState().toJS().App.token;

export const getCurrentPartnerId = () =>
  PartnerLoginApi.getPartnerId(window.store.getState());
export const getPartnerToken = () =>
  PartnerLoginApi.getPartnerToken(window.store.getState());
export const getPartnerRefreshToken = () =>
  PartnerLoginApi.getPartnerRefreshToken(window.store.getState());

export const isRemoteSession = () =>
  !!window.store.getState().getIn(['App', 'remote', 'partnerId']);
export const getRemotePartnerId = () =>
  window.store.getState().getIn(['App', 'remote', 'partnerId']);
