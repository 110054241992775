import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { sentryReduxEnhancer } from 'app/lib/analytics/sentry';
import RemoteSessionMiddleware from 'redux/modules/RemoteSession/v2/middleware';

import rootSaga from '../sagas';
import rootReducer from '../';

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState, additionalMiddlewares) {
  const middlewares = [RemoteSessionMiddleware.handler, sagaMiddleware]
    .concat(additionalMiddlewares)
    .filter(Boolean);

  const enhancer = compose(
    applyMiddleware(...middlewares),
    sentryReduxEnhancer
  );

  const store = createStore(rootReducer, initialState, enhancer);

  window.store = store;
  // Run sagas
  sagaMiddleware.run(rootSaga);

  return store;
}
