export default function validateGBNumber(val) {
  return val === undefined || validateGBLandlineNumber(val.replace(/ /g, ''));
}

export function validateGBMobileNumber(val) {
  const mobRegex = /^(07\d{8,12}|447\d{7,11})$/;
  return val === '' || mobRegex.test(val);
}

export function validateGBLandlineNumber(val) {
  const landRegex = /^0[1238][0-9]{8,9}$/;
  return val === '' || landRegex.test(val);
}
