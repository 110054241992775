const formatPostcode = (postcode) => {
  const trimmedPostcode = postcode.replace(/\s+/g, '');
  let len = 2;
  if (trimmedPostcode.length === 6) {
    len = 3;
  }

  if (trimmedPostcode.length === 7) {
    len = 4;
  }

  return `${trimmedPostcode.slice(0, len)} ${trimmedPostcode.slice(
    len
  )}`.toLocaleUpperCase();
};

export default formatPostcode;
