import moment from 'moment';

const yesterday = moment().subtract(1, 'days');
const dateError = 'Please enter a date in the future';
const formatError = 'Please enter a valid date';
const validDateCharacterLength = 10;
const validateDate = (date) => {
  if (!date) {
    return undefined;
  }
  if (date.length < validDateCharacterLength) {
    return formatError;
  }

  const parsed = moment(date);

  if (!parsed.isValid()) {
    return formatError;
  }

  if (parsed.isBefore(yesterday)) {
    return dateError;
  }

  if (
    parsed.isAfter(
      moment()
        .add(1, 'years')
        .subtract(1, 'days')
    )
  ) {
    return 'Please submit a date no more than 1 year in the future';
  }
  return undefined;
};

export default (date) => {
  const error = validateDate(date);
  return {
    fields: {
      date,
      error,
    },
    formComplete: date && !error,
  };
};
