import Bowser from 'bowser';

export const detectBrowserSupport = () => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  const isValidBrowser = browser.satisfies({
    chrome: '>=71',
    chromium: '>=67',
    edge: '>=18',
    firefox: '>=71',
    // ie: ">=11", // no IE support
    opera: '>=67',
    safari: '>=11',
    samsung_internet: '>=8',
  });

  return !!isValidBrowser;
};

export const getUserAgentDetails = () => {
  return Bowser.parse(window.navigator.userAgent);
};
