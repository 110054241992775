const copyMap = {
  mobile: {
    tariff: {
      label: {
        VALUE_200: 'Value 200',
        VALUE_250: 'Value 250',
        VALUE_500: 'Value 500',
        VALUE_1000: 'Value 1000',
        VALUE_1500: 'Value 1500',
        VALUE_MAX_PLUS: 'Value Max+',
      },
    },
  },
};

export const copyMapper = (key, map = copyMap) => {
  const keys = key.split('.');

  return keys.reduce((previous, current) => {
    return previous && previous[current] ? previous[current] : null;
  }, map);
};
