export default function constructSearchParams(uri) {
  const { search } = new URL(uri);
  const params = {};

  if (!search) {
    return params;
  }

  search
    .replace(/^\?/, '')
    .split('&')
    .map((p) => p.split('='))
    .forEach(([key, value]) => {
      params[key] = value;
    });

  return params;
}
