import axios from 'axios';
import {
  getCurrentApplication,
  getCurrentToken,
  getPartnerToken,
  getPartnerRefreshToken,
  getCurrentPartnerId,
  dispatchAction,
  isRemoteSession,
  getRemotePartnerId,
} from './storeHelper';
import { partnerTokenRefreshed } from '../modules/PartnerLogin/actions';
import { API_URL } from 'config';

export const METHOD = {
  DELETE: 'DELETE',
  POST: 'POST',
  PUT: 'PUT',
  GET: 'GET',
};

export const nonAuthenticatedRequest = (method, endpoint, data, headers) => {
  return axios.create().request({
    url: `${API_URL}/${endpoint}`,
    method,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...headers,
    },
    ...(data ? { data } : {}),
  });
};

export const nonAuthenticatedFullPathRequest = (method, url, data) =>
  axios.create().request({
    url,
    method,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    ...(data ? { data } : {}),
  });

export const genericAuthorizedRequest = (method, url, token, data) => {
  return axios.create().request({
    url,
    method,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      authorization: token,
    },
    ...(data ? { data } : {}),
  });
};

export const jtcRequest = (
  method,
  endpoint,
  data,
  { applicationToken, positionToken, positionId }
) => {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  if (applicationToken) headers.authentication = applicationToken;
  if (positionId) headers.partner = positionId;
  if (positionToken) headers.authorization = positionToken;

  return axios.create().request({
    url: `${API_URL}/${endpoint}`,
    method,
    headers,
    ...(data ? { data } : {}),
  });
};

// FIXME: deprecated, as it is using global state
const authenticatedRequest = (method, endpoint, data) => {
  const partner = isRemoteSession()
    ? getRemotePartnerId()
    : getCurrentPartnerId();

  return nonAuthenticatedRequest(
    method,
    `application/${getCurrentApplication()}/${endpoint}`,
    data,
    { authentication: getCurrentToken(), ...(partner ? { partner } : {}) }
  );
};

const refreshTokenRequest = (refreshToken) =>
  nonAuthenticatedRequest(METHOD.POST, 'partner/access-token', {
    refresh_token: refreshToken,
    grant_type: 'refresh_token',
  });

// FIXME: deprecated, as it is using global state
const partnerAuthorizedRequest = async (
  method,
  endpoint,
  data,
  attempt = 0,
  partnerToken
) => {
  const partnerId = getCurrentPartnerId();
  const token = partnerToken || getPartnerToken();
  const refreshToken = getPartnerRefreshToken();

  try {
    return await nonAuthenticatedRequest(
      method,
      `partner/${partnerId}/${endpoint}`,
      data,
      { Authorization: token }
    );
  } catch (error) {
    if (error.response.status === 401 && attempt < 3) {
      const {
        data: { token_type, access_token, refresh_token },
      } = await refreshTokenRequest(refreshToken);
      const accessToken = `${token_type} ${access_token}`;
      dispatchAction(partnerTokenRefreshed(accessToken, refresh_token));
      return await partnerAuthorizedRequest(
        method,
        endpoint,
        data,
        ++attempt,
        accessToken
      );
    }

    throw error;
  }
};

// FIXME: deprecated, as it is using global state
export const authenticatedDelete = (endpoint) =>
  authenticatedRequest(METHOD.DELETE, endpoint);
// FIXME: deprecated, as it is using global state
export const authenticatedGet = (endpoint, data) =>
  authenticatedRequest(METHOD.GET, endpoint, data);
// FIXME: deprecated, as it is using global state
export const authenticatedPost = (endpoint, data) =>
  authenticatedRequest(METHOD.POST, endpoint, data);
// FIXME: deprecated, as it is using global state
export const authenticatedPut = (endpoint, data) =>
  authenticatedRequest(METHOD.PUT, endpoint, data);
// FIXME: deprecated, as it is using global state
export const partnerAuthorizedPost = (endpoint, data) =>
  partnerAuthorizedRequest(METHOD.POST, endpoint, data);
