import {
  REQUEST_SENDING,
  REQUEST_FETCHING,
  REQUEST_FAIL,
  REQUEST_SUCCESS,
  REQUEST_STATUS_STORE_KEY,
} from 'constants/requestStatus';

//FIXME: deprecated, please don't use functions from this file
export default function(state, storeName) {
  return Object.values(
    state.get(storeName).toJS()[REQUEST_STATUS_STORE_KEY]
  ).some(
    (request) => request === REQUEST_FETCHING || request === REQUEST_SENDING
  );
}

const requestState = (state, name, status) =>
  state.mergeDeep({ [REQUEST_STATUS_STORE_KEY]: { [name]: status } });

export const fetchingRequestState = (state, name) =>
  requestState(state, name, REQUEST_FETCHING);
export const sendingRequestState = (state, name) =>
  requestState(state, name, REQUEST_SENDING);
export const successRequestState = (state, name) =>
  requestState(state, name, REQUEST_SUCCESS);
export const failedRequestState = (state, name) =>
  requestState(state, name, REQUEST_FAIL);
export const cancelRequestState = (state, name) =>
  requestState(state, name, '');

export const createRequestStateObject = (requestNames) => ({
  [REQUEST_STATUS_STORE_KEY]: requestNames.reduce(
    (obj, key) => ({
      ...obj,
      [key]: '',
    }),
    {}
  ),
});
