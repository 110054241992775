import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from 'redux/modules';
import { trackEvent } from 'redux/modules/Analytics/actions';

export const connector = (moduleName, stateFn, actions) => {
  stateFn = stateFn || (() => {});
  actions = actions || {};
  return connect(
    (state, props) => ({
      ...state.get(moduleName).toJS(),
      ...stateFn(state, props),
    }),
    (dispatch) =>
      bindActionCreators(
        { ...actionCreators[moduleName], ...actions },
        dispatch
      )
  );
};
export const analyticsConnector = (
  trackActions,
  moduleName,
  stateFn,
  actions
) => {
  stateFn = stateFn || (() => {});
  actions = actions || {};

  // dispatch directly since it is not possible to
  // map in bindActionCreators given the current
  // setup of 'trackers' files.
  //
  // Trackers are essentially an alias of the trackEvent
  // action in this form, though not distinct actions
  // themselves. While slightly dirty, this allows
  // *all* trackEvent calls via this mechamism to
  // pass through the analytics saga and allow application IDs
  // to be injected correctly.
  const trackFn = (action, properties) =>
    window.store.dispatch(trackEvent(action, properties));

  return connect(
    (state, props) => {
      const composedState = {
        ...state.get(moduleName).toJS(),
        ...stateFn(state, props),
      };
      const boundTrackActions = Object.keys(trackActions).reduce(
        (boundActions, trackFnName) => {
          boundActions[trackFnName] = trackActions[trackFnName].bind(
            null,
            trackFn,
            composedState
          );
          return boundActions;
        },
        {}
      );

      return {
        ...composedState,
        ...boundTrackActions,
      };
    },
    (dispatch) =>
      bindActionCreators(
        { ...actionCreators[moduleName], ...actions },
        dispatch
      )
  );
};
