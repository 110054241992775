export const multiplyPrice = (price, multiplicand) => ({
  ...price,
  value: price.value * multiplicand,
});

export const addPrices = (price, otherPrice) => ({
  ...price,
  value: price.value + otherPrice.value,
});

export const sumPrices = (prices) =>
  prices.reduce((acc, item) => addPrices(acc, item), {
    currency: 'GBP',
    value: 0,
    exponent: 2,
  });

export const subtractPrices = (price, otherPrice) => ({
  ...price,
  value: price.value - otherPrice.value,
});

export const getAnnualPriceFromMonthly = (saving) =>
  saving ? multiplyPrice(saving, 12) : undefined;
