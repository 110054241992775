export function getTariffName(tariffs) {
  let tariffName;
  if (tariffs.electricity) {
    tariffName = tariffs.electricity.quote.tariff.name;
  } else if (tariffs.gas) {
    tariffName = tariffs.gas.quote.tariff.name;
  }

  return tariffName;
}

export function getTariffType(tariffs) {
  let type;
  if (tariffs.electricity) {
    type = tariffs.electricity.quote.tariff.type;
  } else if (tariffs.gas) {
    type = tariffs.gas.quote.tariff.type;
  }

  return type;
}

export function getNewTariff(from, to) {
  const toTariffName = getTariffName(to);
  const fromTariffName = getTariffName(from);

  if (!toTariffName || toTariffName === fromTariffName) {
    return false;
  }

  return toTariffName;
}

export function dependantServiceRemoved(removedService, from, to) {
  const previousServices = Object.keys(from);
  const resultingServices = Object.keys(to);
  const diff = previousServices.filter(
    (s) => !resultingServices.includes(s) && s !== removedService
  );

  return diff;
}

export default function(removedService, changes) {
  const tariffChanged = getNewTariff(changes.from, changes.to);
  return !!tariffChanged;
}
