export default function download(url, filename = 'download') {
  if (!url) {
    throw new TypeError('URL is required');
  }

  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'download';
  a.target = '_blank';
  a.rel = 'noopener noreferrer';
  a.click();
}
