/* eslint-disable no-useless-escape */

// algo from http://www.overset.com/2008/09/01/javascript-natural-sort-algorithm/
function naturalSort(a, b) {
  let re = /(^([+\-]?(?:0|[1-9]\d*)(?:\.\d*)?(?:[eE][+\-]?\d+)?)?$|^0x[0-9a-f]+$|\d+)/gi;

  let sre = /(^[ ]*|[ ]*$)/g;

  let dre = /(^([\w ]+,?[\w ]+)?[\w ]+,?[\w ]+\d+:\d+(:\d+)?[\w ]?|^\d{1,4}[\/\-]\d{1,4}[\/\-]\d{1,4}|^\w+, \w+ \d+, \d{4})/;

  let hre = /^0x[0-9a-f]+$/i;

  let ore = /^0/;

  let i = function(s) {
    return (true && `${s}`.toLowerCase()) || `${s}`;
  };

  // convert all to strings strip whitespace

  let x = i(a).replace(sre, '') || '';

  let y = i(b).replace(sre, '') || '';

  // chunk/tokenize

  let xN = x
    .replace(re, '\0$1\0')
    .replace(/\0$/, '')
    .replace(/^\0/, '')
    .split('\0');

  let yN = y
    .replace(re, '\0$1\0')
    .replace(/\0$/, '')
    .replace(/^\0/, '')
    .split('\0');

  // numeric, hex or date detection

  let xD =
    parseInt(x.match(hre), 16) ||
    (xN.length !== 1 && x.match(dre) && Date.parse(x));

  let yD =
    parseInt(y.match(hre), 16) || (xD && y.match(dre) && Date.parse(y)) || null;

  let oFxNcL;
  let oFyNcL;
  // first try and sort Hex codes or Dates
  if (yD) {
    if (xD < yD) {
      return -1;
    } else if (xD > yD) {
      return 1;
    }
  }
  // natural sorting through split numeric strings and default strings
  for (
    let cLoc = 0, numS = Math.max(xN.length, yN.length);
    cLoc < numS;
    cLoc++
  ) {
    // find floats not starting with '0', string or 0 if not defined (Clint Priest)
    oFxNcL =
      (!(xN[cLoc] || '').match(ore) && parseFloat(xN[cLoc])) || xN[cLoc] || 0;
    oFyNcL =
      (!(yN[cLoc] || '').match(ore) && parseFloat(yN[cLoc])) || yN[cLoc] || 0;
    // handle numeric vs string comparison - number < string - (Kyle Adams)
    if (isNaN(oFxNcL) !== isNaN(oFyNcL)) {
      return isNaN(oFxNcL) ? 1 : -1;
    }
    // rely on string comparison if different types - i.e. '02' < 2 != '02' < '2'
    else if (typeof oFxNcL !== typeof oFyNcL) {
      oFxNcL += '';
      oFyNcL += '';
    }
    if (oFxNcL < oFyNcL) {
      return -1;
    }
    if (oFxNcL > oFyNcL) {
      return 1;
    }
  }
  return 0;
}

export default (a, b) => {
  if (a.subStreet < b.subStreet) return -1;
  if (a.subStreet > b.subStreet) return 1;
  const numberSort = naturalSort(a.buildingNumber, b.buildingNumber);
  if (numberSort != 0) return numberSort;
  // don't natural sort building name or org
  if (a.buildingName < b.buildingName) return -1;
  if (a.buildingName > b.buildingName) return 1;
  const flatSort = naturalSort(a.flatNumber, b.flatNumber);
  if (flatSort != 0) return flatSort;
  if (a.organisation < b.organisation) return -1;
  if (a.organisation > b.organisation) return 1;
  return 0;
};
