/**
 * Convert strings "true" or "false" into respective boolean
 * values. Used for MUI radio inputs which output string values.
 */
const convertStringToBool = (val) => {
  if (val === 'false') return false;
  if (val === 'true') return true;
  return val;
};

export { convertStringToBool };
