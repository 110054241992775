const ROUTING_STORAGE_KEY = 'routing';

export function getDataFromLocalStorage(key) {
  const retrievedData = window.localStorage.getItem(key);
  const dataFormated =
    retrievedData !== null ? JSON.parse(retrievedData) : null;
  return dataFormated;
}

export function setDataToLocalStorage(key, value) {
  window.localStorage.setItem(key, JSON.stringify(value));
}

export const getRouting = () => {
  if (!window.sessionStorage) {
    return false;
  }

  try {
    const routing = JSON.parse(
      window.sessionStorage.getItem(ROUTING_STORAGE_KEY)
    );
    return routing || [];
  } catch (e) {
    return false;
  }
};

export const saveNavigatedRoute = (route) => {
  const routing = getRouting();

  if (!routing) return false;

  if (!routing.includes(route)) {
    routing.push(route);
  }

  return saveRouting(routing);
};

export const saveRouting = (routing) => {
  if (!window.sessionStorage) {
    return false;
  }

  window.sessionStorage.setItem(ROUTING_STORAGE_KEY, JSON.stringify(routing));
  return true;
};

export const clearRouting = (routes = []) => saveRouting([...routes]);

export const popRouting = () => {
  if (!window.sessionStorage) {
    return false;
  }

  const routing = getRouting();

  routing.pop();

  return saveRouting(routing);
};

export const removeRoute = (route) => {
  if (!window.sessionStorage) {
    return false;
  }

  const routing = getRouting();
  const index = routing.indexOf(route);

  if (index > -1) {
    routing.splice(index, 1);
  }

  return saveRouting(routing);
};
