export function formatNumber(value, opts = {}) {
  if (value === null || value === undefined) return value;
  return value
    .toLocaleString('en-GB', opts)
    .replace('\xA0', '')
    .replace('.00', '');
}

export function formatPrice(priceObject) {
  const amount = priceObject.value / Math.pow(10, priceObject.exponent);
  return formatNumber(amount, {
    style: 'currency',
    currency: priceObject.currency,
  });
}
